import { Button } from '@design-system';
import type { GetStaticProps } from 'next';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

import PageSection from '../components/Layout/PageSection';
import { SimpleLayout } from '../components/Layout/SimpleLayout';
import UnderlinedLink from '../components/UnderlinedLink';
import { ERROR_PAGE_TYPE } from '../constants';
import { TrackPageView } from '../utils/analytics';
import { pageTranslations } from '../utils/translations';

const Page404 = () => {
  const { t } = useTranslation('404');

  useEffect(() => {
    TrackPageView({ page: ERROR_PAGE_TYPE });
  }, []);

  return (
    <SimpleLayout canonicalUrl="" title={t('title')}>
      <PageSection className="h-screen">
        <div className="flex h-full w-full flex-col items-center justify-center gap-6">
          <div className="mx-auto flex w-full max-w-md flex-col gap-1">
            <h1 className="w-full text-balance text-center text-md font-bold sm:text-lg">
              {t('title')}
            </h1>
            <p className="w-full text-balance text-center sm:text-md">
              <Trans i18nKey="text" t={t}>
                You can contact our
                <UnderlinedLink
                  href="mailto:support@avantarte.com"
                  target="_blank"
                >
                  support@avantarte.com
                </UnderlinedLink>
                if you have any questions.
              </Trans>
            </p>
          </div>

          <Button
            label={t('button')}
            onClick={() => {
              window.location.replace(window.location.origin);
            }}
            variant="primary"
          />
        </div>
      </PageSection>
    </SimpleLayout>
  );
};

export default Page404;

export const getStaticProps: GetStaticProps = async ({
  locale,
  locales,
  preview = false,
}) => {
  return {
    props: {
      preview,
      locale,
      locales,
      ...(await pageTranslations(locale!, ['404'])),
    },
  };
};
